import React from 'react';
import { GetServerSideProps } from 'next';
import { signIn } from 'next-auth/react';

type LoginPageProps = {
	callbackUrl: string;
};

const Login = ({ callbackUrl }: LoginPageProps): React.JSX.Element => {
	React.useEffect(() => {
		signIn('azure-ad', {
			callbackUrl
		});
	}, []);

	return <></>;
};

export const getServerSideProps: GetServerSideProps = async (context) => {
	const { callbackUrl } = context.query || {};
	const fallbackCallbackUrl = typeof callbackUrl === 'string' ? callbackUrl : process.env.NEXT_PUBLIC_URL || '/';

	return {
		props: {
			callbackUrl: fallbackCallbackUrl
		}
	};
};

export default Login;
